import axios from "axios";
import { makeAutoObservable } from "mobx";
import { saveUserSetting, getUserSetting } from "../db/apis";
import { TreeNodeElement } from "../components/selector/tree_node";

class GlobalStore {
  loading = false;
  selectedDam = "NPL";
  startYearActual = 2564;
  startMonthActual = 1;
  startYearPrediction = 0;
  startMonthPrediction = 1;
  endYearPrediction = 0;
  endMonthPrediction = 1;
  inflowCount = 3;
  selectedInflow = [[], [], []];
  manualOutflows = [[], [], []];
  manualNamPanflows = [[], [], []];
  manualInflows = [[], [], []];

  constructor() {
    makeAutoObservable(this);
  }

  stopLoad() {
    this.loading = false;
  }

  async saveSetting(data) {
    const result = await saveUserSetting(data);
    this.selectedDam = data.selectedDam;
    this.startYearActual = data.startYearActual;
    this.startMonthActual = data.startMonthActual;
    this.startYearPrediction = data.startYearPrediction;
    this.startMonthPrediction = data.startMonthPrediction;
    this.endYearPrediction = data.endYearPrediction;
    this.endMonthPrediction = data.endMonthPrediction;
    this.inflowCount = data.inflowCount;
    this.selectedInflow = data.selectedInflow;
    this.manualOutflows = data.manualOutflows;
    this.manualNamPanflows = data.manualNamPanflows;
    this.manualInflows = data.manualInflows;
    // if (result.success){
    //   console.log("Setting saved successfully");
    // }
  }

  async loadSetting() {
    const result = await getUserSetting();
    if (result.success) {
      for (let i = 0; i < result.setting.selectedInflow.length; i++) {
        for (let j = 0; j < result.setting.selectedInflow[i].length; j++) {
          result.setting.selectedInflow[i][j] = new TreeNodeElement(
            result.setting.selectedInflow[i][j].value
          );
        }
      }
      // console.log("Setting loaded successfully");
      return result.setting;
    } else {
      // console.log("No setting found for this user");
      return {
        selectedDam: this.selectedDam,
        startYearActual: this.startYearActual,
        startMonthActual: this.startMonthActual,
        startYearPrediction: this.startYearPrediction,
        startMonthPrediction: this.startMonthPrediction,
        endYearPrediction: this.endYearPrediction,
        endMonthPrediction: this.endMonthPrediction,
        inflowCount: this.inflowCount,
        selectedInflow: this.selectedInflow,
        manualOutflows: this.manualOutflows,
        manualNamPanflows: this.manualNamPanflows,
        manualInflows: this.manualInflows,
      };
    }
  }
}

export default GlobalStore;
