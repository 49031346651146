import { FormControl, Stack, FormHelperText, Divider } from "@mui/material";
import { InflowSelector, Node } from "./selector/inflow";
import React, { useEffect, useMemo, useState } from "react";
import { ManualNampanForm } from "./manual/nampan_form";
import { ManualOutflowForm } from "./manual/outflow_form2";
import { ManualInflowForm } from "./manual/inflow_form";
import { OutflowSelector } from "./selector/outflow";

// import { ManualInputLengthCount } from "./manual_input_length_count"

export function InflowForm({
  inflowCount,
  predictionPeriod,
  selectedInflow,
  setSelectedInflow,
  selectedOutflow,
  setSelectedOutflow,
  manualOutflows,
  setManualOutflows,
  manualNamPanflows,
  setManualNamPanflows,
  manualInflows,
  setManualInflows,
  selectedDam,
}) {
  const [manualInflowMonthLeft, setManualInflowMonthLeft] = useState(
    Array(inflowCount).fill(predictionPeriod)
  );
  const [manualOutflowMonthLeft, setManualOutflowMonthLeft] = useState(
    Array(inflowCount).fill(predictionPeriod)
  );

  useEffect(() => {
    const monthLeft = Array(inflowCount).fill(predictionPeriod);
    for (let i = 0; i < inflowCount; i++) {
      if (selectedInflow[i] !== undefined) {
        const totalMonths = selectedInflow[i].reduce((total, current) => {
          if (current !== undefined && current.value.value !== "Manual") {
            const month = current.value.value.split(" ")[2];
            return total - month;
          }
          return total;
        }, predictionPeriod);
        monthLeft[i] = totalMonths;
      }
    }
    setManualInflowMonthLeft(monthLeft);
  }, [selectedInflow]);
  useEffect(() => {
    const monthLeft = Array(inflowCount).fill(predictionPeriod);
    for (let i = 0; i < inflowCount; i++) {
      if (selectedOutflow[i] !== undefined) {
        const totalMonths = selectedOutflow[i].reduce((total, current) => {
          if (current !== undefined && current.value.value !== "Manual") {
            const month = current.value.value.split(" ")[2];
            return total - month;
          }
          return total;
        }, predictionPeriod);
        monthLeft[i] = totalMonths;
      }
    }
    setManualInflowMonthLeft(monthLeft);
  }, [selectedOutflow]);
  // useEffect(() => {
  // setManualInflows((prev) => {
  //   let newManualInflows = [...prev];
  //   newManualInflows.map((manualInflow, lineIndex) => {
  //     if (selectedInflow[lineIndex] !== undefined) {
  //       newManualInflows[lineIndex] = Array(
  //         selectedInflow[lineIndex].length
  //       ).fill([]);
  //     }
  //     return newManualInflows;
  //   });
  //   // if (newManualInflows[lineIndex] === undefined) {
  //   //   newManualInflows[lineIndex] = Array(inflowArray.length).fill([]);
  //   // }
  //   return newManualInflows;
  // });
  // }, [selectedInflow]);

  return Array(inflowCount)
    .fill()
    .map((_, i) => (
      <FormControl id="month-split" key={i}>
        <div style={{ marginTop: "6px" }}>
          <label>Scenario {i + 1}</label>
        </div>
        {/* <FormLabel id="demo-radio-buttons-group-label">Inflow</FormLabel> */}
        <div style={{ marginTop: "5px" }}>Inflow</div>
        <FormHelperText>
          กรุณาเลือกรูปแบบน้ำเข้า (Inflow) ปรับแต่ง % และจำนวนเดือนให้ครบถ้วน
        </FormHelperText>
        <InflowSelector
          value={selectedInflow[i]}
          setValue={(e) => {
            setSelectedInflow((prev) => {
              const newSelectedInflow = [...prev];
              newSelectedInflow[i] = e;
              return newSelectedInflow;
            });
          }}
          selectedDam={selectedDam}
          predictionPeriod={predictionPeriod}
          manualInflows={manualInflows[i]}
        />
        <Divider sx={{ m: 2 }} />
        {/* <Stack spacing={1}> */}
        <ManualInflowForm
          selectedInflow={selectedInflow[i]}
          manualInflows={manualInflows}
          setManualInflows={setManualInflows}
          lineIndex={i}
          predictionPeriod={manualInflowMonthLeft[i]}
        />
        <div>Outflow</div>
        <FormHelperText>
          กรุณาใส่ปริมาณน้ำออก (Outflow) ในแต่ละเดือนให้ครบถ้วน
        </FormHelperText>
        <OutflowSelector
          value={selectedOutflow[i]}
          setValue={(e) => {
            setSelectedOutflow((prev) => {
              const newSelectedOutflow = [...prev];
              newSelectedOutflow[i] = e;
              return newSelectedOutflow;
            });
          }}
          selectedDam={selectedDam}
          predictionPeriod={predictionPeriod}
          manualOutflows={manualOutflows[i]}
        />
        <ManualOutflowForm
          selectedOutflow={selectedOutflow[i]}
          manualOutflows={manualOutflows}
          setManualOutflows={setManualOutflows}
          lineIndex={i}
          predictionPeriod={manualOutflowMonthLeft[i]}
        />
        <Divider sx={{ m: 2 }} />
        <div>Divertion Inflow</div>
        <FormHelperText>
          กรุณาใส่ปริมาณน้ำผัน ในแต่ละเดือนให้ครบถ้วน
        </FormHelperText>
        <ManualNampanForm
          manualNampans={manualNamPanflows}
          setManualNampans={setManualNamPanflows}
          idx={i}
          predictionPeriod={predictionPeriod}
        />
        {/* </Stack> */}
      </FormControl>
    ));
}
export default InflowForm;
