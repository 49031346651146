import axios, * as others from 'axios';
import { makeAutoObservable } from "mobx";

const create_axios_instance = (token) => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
}

class AxiosStore {
    accessToken = null

    constructor() {
        makeAutoObservable(this);
        this.baseAxios = null
    }

    get isAuthenticated() {
        return !!this.accessToken
    }

    setAccessToken(token){
        // this.baseAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        this.baseAxios = create_axios_instance(token)
        this.accessToken = token;
    }

    signout(){
        this.accessToken = null
    }
}

export default AxiosStore;
