import WarningLevelData from "../../db/warning_level";
import {
  warningDarkRed,
  warningDarkRedLight,
  warningRed,
  warningRedLight,
  warningYellow,
  warningYellowLight,
} from "./color";

function getSeletedDamWarningLevel(selectedDam) {
  for (var i = 0; i < WarningLevelData.length; i++) {
    if (WarningLevelData[i].dam === selectedDam) {
      return WarningLevelData[i];
    }
  }
}
export function GetWarningLevelLines(selectedDam, initializeLineLabel) {
  var lineResult = [];
  var selectedDamWarningLevel = getSeletedDamWarningLevel(selectedDam);
  var darkRedLine = {
    label: "ปริมาณกักเก็บต่ำสุด",
    data: initializeLineLabel.map((label) => {
      var month = Number(label.split("/")[1]);
      if (month === 0) {
        return 0;
      }
      var warningLevel =
        selectedDamWarningLevel.warningLevel.find(
          (level) => level.Month === month
        ).Dark_Red || 0;
      return warningLevel;
    }),
    borderColor: warningDarkRed,
    backgroundColor: warningDarkRedLight,
    fill: true,
  };
  //   252	50	59	red
  var redLine = {
    label: "เตือนขาดแคลนน้ำ",
    data: initializeLineLabel.map((label) => {
      var month = Number(label.split("/")[1]);
      if (month === 0) {
        return 0;
      }
      var warningLevel =
        selectedDamWarningLevel.warningLevel.find(
          (level) => level.Month === month
        ).Red || 0;
      return warningLevel;
    }),
    borderColor: warningRed,
    backgroundColor: warningRedLight,
    fill: true,
  };
  // 253	181	6	yellow
  var yellowLine = {
    label: "เฝ้าระวัง",
    data: initializeLineLabel.map((label) => {
      var month = Number(label.split("/")[1]);
      if (month === 0) {
        return 0;
      }
      var warningLevel =
        selectedDamWarningLevel.warningLevel.find(
          (level) => level.Month === month
        ).Yellow || 0;
      return warningLevel;
    }),
    borderColor: warningYellow,
    backgroundColor: warningYellowLight,
    fill: true,
  };
  lineResult.push(darkRedLine, redLine, yellowLine);
  return lineResult;
}

export default GetWarningLevelLines;
