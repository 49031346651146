import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { DamName } from "../../db/water_data";

export function DamSelector({ selectedDam, setSelectedDam }) {
  return (
    <Stack direcion="row">
      <FormControl margin="normal" sx={{ m: 1 }}>
        <InputLabel id="demo-simple-select-label">อ่างเก็บน้ำ</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedDam}
          label="อ่างเก็บน้ำ"
          onChange={(e) => setSelectedDam(e.target.value)}
        >
          <MenuItem value="NPL">{DamName["NPL"]}</MenuItem>
          <MenuItem value="DK">{DamName["DK"]}</MenuItem>
          <MenuItem value="KY">{DamName["KY"]}</MenuItem>
          <MenuItem value="PS">{DamName["PS"]}</MenuItem>
          <MenuItem value="DK+KY+NPL">{DamName["DK+KY+NPL"]}</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
}

export default DamSelector;
