import axios from "axios";
import { axiosStore } from "../store";

// const backendUrl = "http://localhost:8000";
// const backendUrl = "https://waterforecasty-ibaapdpdcq-as.a.run.app";
const backendUrl = process.env.REACT_APP_API_URL;

export async function fetchInflowPredictionData(selectedDam, algoList) {
  try {
    const response = await axiosStore.baseAxios.post(`${backendUrl}/predict`, {
      water_type: selectedDam,
      algoList: algoList,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

export async function fetchOutflowData(selectedDam, algoList) {
  try {
    const response = await axiosStore.baseAxios.post(`${backendUrl}/outflow`, {
      water_type: selectedDam,
      algoList: algoList,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

export async function fetchHistoricalOutflow(
  selectedDam,
  startActualYear,
  startActualMonth
) {
  try {
    const response = await axiosStore.baseAxios.post(
      `${backendUrl}/historical_outflow`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        water_type: selectedDam,
        actual_start_year: startActualYear,
        actual_start_month: startActualMonth,
      }
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

export async function fetchHistoricalInflow(
  selectedDam,
  startActualYear,
  startActualMonth
) {
  try {
    const response = await axiosStore.baseAxios.post(
      `${backendUrl}/historical_inflow`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        water_type: selectedDam,
        actual_start_year: startActualYear,
        actual_start_month: startActualMonth,
      }
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

export async function getInflowOption(selectedDam) {
  try {
    const response = await axiosStore.baseAxios.get(
      `${backendUrl}/get_inflow_year_options/${selectedDam}`
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

export async function getOutflowOption(selectedDam) {
  try {
    const response = await axiosStore.baseAxios.get(
      `${backendUrl}/get_outflow_year_options/${selectedDam}`
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

export async function saveUserSetting(new_setting) {
  try {
    const response = await axiosStore.baseAxios.put(
      `${backendUrl}/user/setting`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        new_setting: new_setting,
      }
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

export async function getUserSetting() {
  try {
    const response = await axiosStore.baseAxios.get(
      `${backendUrl}/user/setting`
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    return [];
  }
}
