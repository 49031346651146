import { ManualInputLengthCount } from "./count_manual_input";

export function getMonthleft(predictionPeriod, selectedInflow, manualInflow) {
    let monthCount = 0;
    if (selectedInflow === undefined) return predictionPeriod;
    for (let i = 0; i < selectedInflow.length; i++) {
      if (selectedInflow[i].value.value !== "Manual") {
        monthCount += parseInt(selectedInflow[i].value.value.split(" ")[2]);
      } else {
        manualInflow[i] = manualInflow[i] || [];
        monthCount += ManualInputLengthCount(manualInflow[i]); //parseInt(manualInflow[i].length);
        // console.log(manualInflow[i].length, monthCount);
      }
    }
    return predictionPeriod - monthCount;
  }