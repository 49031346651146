import { writeFile, utils } from "xlsx";
import { pointLabelSegment } from "./graph";
import { Button } from "@mui/material";
import { GetAppOutlined } from "@mui/icons-material";
import { dark } from "@mui/material/styles/createPalette";

export const DownloadButton = ({
  filename,
  capData,
  capPrediction,
  inflowData,
  inflowPrediction,
  outflowData,
  outflowPrediction,
  selectedDam,
  selectedInflow,
  selectedInflowsArr,
  selectedOutflow,
  selectedOutflowsArr,
  manualInflows,
  manualOutflows,
}) => {
  const handleDownload = () => {
    let data = [];
    let header = [
      "Water Level",
      "อ่างเก็บน้ำ",
      "ปี",
      "เดือน",
      "ปริมาณน้ำ",
      "หน่วย",
      "Algorithm",
      "Scenario",
    ];
    data.push(header);
    let capDataMap = new Map();
    let inflowDataMap = new Map();
    // Graph1 Actual Scenario
    for (let i = 0; i < capData.length; i++) {
      let { year, month, value } = capData[i];
      let row = [
        "",
        selectedDam,
        year,
        month,
        value,
        "ล้าน ลบ.ม.",
        "Actual",
        0,
      ];
      capDataMap.set(String(year) + String(month), value);
      data.push(row);
    }
    // Water level Table Prediction Scenario
    for (let i = 0; i < selectedInflow.length; i++) {
      let name = "Based on: " + selectedInflow[i]?.join(", ");
      let isFirst = true;
      let NaNLength = 0;
      // Row
      for (let j = 0; j < capPrediction[i].data.length; j++) {
        const dataPoint = capPrediction[i].data[j];
        let { year, month, value } = dataPoint;
        // value = isNaN(value)
        //   ? capDataMap.get(String(year) + String(month))
        //   : value;
        if (!isNaN(value)) {
          if (isFirst) {
            isFirst = false;
            NaNLength = j + 1;
            continue;
          }
          let label = pointLabelSegment(
            name,
            j,
            value,
            NaNLength,
            selectedInflowsArr,
            selectedInflow,
            manualInflows
          ).split(":")[0];
          let row = [
            "",
            selectedDam,
            year,
            month,
            value,
            "ล้าน ลบ.ม.",
            label,
            i + 1,
          ];
          data.push(row);
        }
      }
    }
    data.push([]);
    header = [
      "Inflow",
      "อ่างเก็บน้ำ",
      "ปี",
      "เดือน",
      "ปริมาณน้ำเข้า",
      "หน่วย",
      "Algorithm",
      "Scenario",
    ];
    data.push(header);
    // Graph2
    for (let i = 0; i < inflowData.data.length; i++) {
      let { year, month, value } = inflowData.data[i];
      let row = [
        "",
        selectedDam,
        year,
        month,
        value,
        "ล้าน ลบ.ม.",
        "Historical",
        0,
      ];
      inflowDataMap.set(String(year) + String(month), value);
      data.push(row);
    }

    // Inflows Table Prediction Scenario
    for (let i = 0; i < selectedInflow.length; i++) {
      let name = "Based on: " + selectedInflow[i]?.join(", ");
      let isFirst = true;
      let NaNLength = 0;
      // Row
      for (let j = 0; j < inflowPrediction[i].data.length; j++) {
        const dataPoint = inflowPrediction[i].data[j];
        let { year, month, value } = dataPoint;
        // value = isNaN(value)
        //   ? inflowDataMap.get(String(year) + String(month))
        //   : value;
        if (!isNaN(value)) {
          if (isFirst) {
            isFirst = false;
            NaNLength = j + 1;
            continue;
          }
          let label = pointLabelSegment(
            name,
            j,
            value,
            NaNLength,
            selectedInflowsArr,
            selectedInflow,
            manualInflows
          ).split(":")[0];
          let row = [
            "",
            selectedDam,
            year,
            month,
            value,
            "ล้าน ลบ.ม.",
            label,
            i + 1,
          ];
          data.push(row);
        }
      }
    }

    // Outflow Table
    data.push([]);
    header = [
      "Outflow",
      "อ่างเก็บน้ำ",
      "ปี",
      "เดือน",
      "ปริมาณน้ำเข้า",
      "หน่วย",
      "Algorithm",
      "Scenario",
    ];
    data.push(header);
    for (let i = 0; i < outflowData.data.length; i++) {
      let { year, month, value } = outflowData.data[i];
      let row = [
        "",
        selectedDam,
        year,
        month,
        value,
        "ล้าน ลบ.ม.",
        "Historical",
        0,
      ];
      inflowDataMap.set(String(year) + String(month), value);
      data.push(row);
    }

    // Outflows Table Prediction Scenario
    for (let i = 0; i < selectedOutflow.length; i++) {
      let name = "Based on: " + selectedOutflow[i]?.join(", ");
      let isFirst = true;
      let NaNLength = 0;
      // Row
      for (let j = 0; j < outflowPrediction[i].data.length; j++) {
        const dataPoint = outflowPrediction[i].data[j];
        let { year, month, value } = dataPoint;
        if (!isNaN(value)) {
          if (isFirst) {
            isFirst = false;
            NaNLength = j + 1;
            continue;
          }
          let label = pointLabelSegment(
            name,
            j,
            value,
            NaNLength,
            selectedOutflowsArr,
            selectedOutflow,
            manualOutflows
          ).split(":")[0];
          let row = [
            "",
            selectedDam,
            year,
            month,
            value,
            "ล้าน ลบ.ม.",
            label,
            i + 1,
          ];
          data.push(row);
        }
      }
    }

    exportExcelFromDict(filename, data);
  };
  // return <button onClick={handleDownload}>Download Excel</button>;
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "black",
        height: "32px",
        position: "absolute",
        top: "10px",
        right: "150px",
      }}
      onClick={handleDownload}
      endIcon={<GetAppOutlined />}
    >
      Export Excel
    </Button>
  );
};

export const exportExcelFromDict = (filename, data) => {
  const wb = utils.book_new();

  // Convert the data array to a worksheet
  const ws = utils.json_to_sheet(data, {
    header: Object.keys(data[0]),
    skipHeader: true,
  });

  // Set column width to 30 for all columns
  ws["!cols"] = Object.keys(data[0]).map(() => ({ wch: 30 }));

  // Merge cells on the first row
  //   const merge = [
  //     { s: { r: 0, c: 0 }, e: { r: 0, c: Object.keys(data[0]).length - 1 } },
  //   ];
  //   ws["!merges"] = merge;

  // Append the worksheet to the workbook
  utils.book_append_sheet(wb, ws, "Sheet1");

  // Write the workbook to a file
  writeFile(wb, `${filename}.xlsx`);
};
