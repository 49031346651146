export const DamCapLevelData = [
  {
    Dam: "NPL",
    Capacity: 187.62,
  },
  {
    Dam: "DK",
    Capacity: 79.411,
  },
  {
    Dam: "KY",
    Capacity: 51.1,
  },
  {
    Dam: "PS",
    Capacity: 295,
  },
  {
    Dam: "DK+KY+NPL",
    Capacity: 318.131,
  },
];
