import { TextField } from "@mui/material";
import React from "react";
import { ManualInputLengthCount } from "../util/count_manual_input";

export function ManualNampanForm({
  manualNampans,
  setManualNampans,
  idx,
  predictionPeriod,
}) {
  return (
    <>
      <TextField
        id="tags-filled"
        label="Manual Diversion Inflow"
        placeholder="กรอกค่าทีละเดือน โดยสามารถกด Enter เพื่อแยกค่าในแต่ละเดือน"
        variant="filled"
        multiline
        error={manualNampans[idx].length > predictionPeriod}
        maxRows={4}
        type="number" // Use type="text" with comma separation
        helperText={
          manualNampans[idx].length > predictionPeriod
            ? "กรุณากรอกค่าไม่เกินจำนวนเดือนที่เลือก"
            : "(ล้าน ลบ.ม./เดือน)" +
              (predictionPeriod - ManualInputLengthCount(manualNampans[idx]) > 0
                ? `, ใส่ได้ ${predictionPeriod} เดือน, คงเหลือ ` +
                  (predictionPeriod -
                    ManualInputLengthCount(manualNampans[idx])) +
                  " เดือน"
                : ", ครบแล้ว")
        }
        value={manualNampans[idx].join(", ")} // Join numbers with comma for display
        onChange={(event) => {
          if (event.target.value.endsWith(",")) {
            event.target.value += " ";
          }
          // Validate input: Only number
          if (
            isNaN(event.target.value.split(", ").pop()) &&
            !event.target.value.split(", ").pop().includes("-")
          ) {
            event.target.value = event.target.value.slice(0, -1);
          }
          let newNumbers = event.target.value.replace("\n", ", ").split(", ");
          // Input = "delete" => pop last element
          if (event.nativeEvent.inputType === "deleteContentBackward") {
            setManualNampans((prev) => {
              let newManualNampans = [...prev];
              newManualNampans[idx].pop();
              return newManualNampans;
            });
            return;
          }
          setManualNampans((prev) => {
            let newManualNampans = [...prev];
            newManualNampans[idx] = newNumbers;
            return newManualNampans;
          });
          // }
          // setManualNampans(newNumbers.filter((num) => !isNaN(num))); // Filter out non-numbers
        }}
      />
    </>
  );
}
