import { TextField, Box } from "@mui/material";
import React from "react";
import { TreeNodeElement } from "../selector/tree_node";

export function ManualInflowForm({
  selectedInflow,
  manualInflows,
  setManualInflows,
  lineIndex,
  predictionPeriod,
}) {
  let nodeInflow = new TreeNodeElement(selectedInflow);
  if (nodeInflow.value !== undefined) {
    let inflowArray = nodeInflow.value.toString().split(",");
    return (
      <>
        {inflowArray.map((inflow, i) => {
          let manualInflow =
            manualInflows[lineIndex][i] === undefined
              ? []
              : manualInflows[lineIndex][i];
          return (
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              key={`manual-inflow-${lineIndex}-${i}`}
            >
              {inflow === "Manual" && (
                <TextField
                  id="tags-filled"
                  key={`manual-inflow-${lineIndex}-${i}`}
                  label="Manual Inflow"
                  placeholder="กรอกค่าทีละเดือน โดยสามารถกด Enter เพื่อแยกค่าในแต่ละเดือน"
                  // error={manualInflow.length > predictionPeriod}
                  helperText={
                    // manualInflow.length > predictionPeriod?
                    // "กรุณากรอกค่าไม่เกินจำนวนเดือนที่เลือก":
                    "(ล้าน ลบ.ม./เดือน)"
                    // +
                    //   (predictionPeriod -
                    //     manualInputLengthCount(manualInflow) >
                    //   0
                    //     ? ", คงเหลือ " +
                    //       (predictionPeriod -
                    //         manualInputLengthCount(manualInflow)) +
                    //       " เดือน"
                    //     : ", ครบแล้ว")
                  }
                  variant="filled"
                  multiline
                  maxRows={4}
                  type="number" // Use type="text" with comma separation
                  value={manualInflow.join(", ") || ""} // Join numbers with comma for display
                  onChange={(event) => {
                    if (
                      event.nativeEvent.inputType === "deleteContentBackward"
                    ) {
                      setManualInflows((prev) => {
                        let newManualInflows = [...prev];
                        newManualInflows[lineIndex][i].pop();
                        return newManualInflows;
                      });
                      return;
                    }
                    if (event.target.value.endsWith(",")) {
                      event.target.value += " ";
                    }
                    // Validate input: Only number
                    if (isNaN(event.target.value.split(", ").pop())) {
                      event.target.value = event.target.value.slice(0, -1);
                    }
                    let newNumbers = event.target.value
                      .replace("\n", ", ")
                      .split(", ");
                    // if length > predictionPeriod, remove the last elements
                    setManualInflows((prev) => {
                      let newManualInflows = [...prev];
                      newManualInflows[lineIndex][i] = newNumbers;
                      return newManualInflows;
                    });
                    // setManualOutflows(newNumbers.filter((num) => !isNaN(num))); // Filter out non-numbers
                  }}
                ></TextField>
              )}
            </Box>
          );
        })}
      </>
    );
    // for (let i = 0; i < inflowArray.length; i++) {
    //   if (inflowArray[i] === "Manual") {
    //     console.log(
    //       "manualInflows",
    //       manualInflows[lineIndex][manualInflowIdx],
    //       lineIndex,
    //       manualInflowIdx
    //     );
    //     manualInflowForm.push(
    //       <TextField
    //         id="tags-filled"
    //         key={`manual-inflow-${lineIndex}-${i}`}
    //         label="Manual Inflow"
    //         placeholder="กรอกค่าทีละเดือน โดยสามารถกด Enter เพื่อแยกค่าในแต่ละเดือน"
    //         error={
    //           manualInflows[lineIndex][manualInflowIdx].length >
    //           predictionPeriod
    //         }
    //         // helperText="(ล้าน ลบ.ม./เดือน)"
    //         helperText={
    //           manualInflows[lineIndex][manualInflowIdx].length >
    //           predictionPeriod
    //             ? "กรุณากรอกค่าไม่เกินจำนวนเดือนที่เลือก"
    //             : "(ล้าน ลบ.ม./เดือน)" +
    //               (predictionPeriod -
    //                 manualInputLengthCount(
    //                   manualInflows[lineIndex][manualInflowIdx]
    //                 ) >
    //               0
    //                 ? ", คงเหลือ " +
    //                   (predictionPeriod -
    //                     manualInputLengthCount(
    //                       manualInflows[lineIndex][manualInflowIdx]
    //                     )) +
    //                   " เดือน"
    //                 : ", ครบแล้ว")
    //         }
    //         variant="filled"
    //         multiline
    //         maxRows={4}
    //         type="text" // Use type="text" with comma separation
    //         value={manualInflows[lineIndex][manualInflowIdx].join(", ")} // Join numbers with comma for display
    //         onChange={(event) => {
    //           if (event.nativeEvent.inputType === "deleteContentBackward") {
    //             setManualInflows((prev) => {
    //               let newManualInflows = [...prev];
    //               newManualInflows[lineIndex][manualInflowIdx] =
    //                 newManualInflows[lineIndex][manualInflowIdx].slice(0, -1);
    //               return newManualInflows;
    //             });
    //             return;
    //           }
    //           let newNumbers = event.target.value
    //             .replace("\n", ", ")
    //             .split(", ");
    //           console.log("newNumbers", newNumbers);
    //           // if length > predictionPeriod, remove the last elements
    //           setManualInflows((prev) => {
    //             let newManualInflows = [...prev];
    //             console.log(
    //               "newManualInflows",
    //               newManualInflows,
    //               lineIndex,
    //               manualInflowIdx
    //             );
    //             newManualInflows[lineIndex][manualInflowIdx] = newNumbers;
    //             return newManualInflows;
    //           });
    //           // setManualOutflows(newNumbers.filter((num) => !isNaN(num))); // Filter out non-numbers
    //         }}
    //       />
    //     );
    //     manualInflowIdx++;
    //   }
    // }
  }
}
