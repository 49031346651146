import { DamName } from "../../db/water_data";
import { DamCapLevelData } from "../../db/dam_capacity_level";
import { GetWarningLevelLines } from "./warning_level_line";
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import { globalStore } from "../../store/index";

import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import {
  AddInflowToLine,
  FetchInflowData,
  FetchOutflowData,
  GetInflowsLineData,
} from "./graph_utils";
import {
  line1DarkColor,
  line1LightColor,
  line2DarkColor,
  line2LightColor,
  line3DarkColor,
  line3LightColor,
} from "./color";
import { fetchHistoricalInflow, fetchHistoricalOutflow } from "../../db/apis";
import { observer } from "mobx-react-lite";
import { configure } from "mobx";
import { DownloadButton } from "./export";
Chart.register(annotationPlugin);
Chart.defaults.font.family = "Tahoma";

configure({
  enforceActions: "never",
});

function extractSelectedInflows(selectedInflows) {
  const result = [];
  if (selectedInflows === undefined) return result;
  for (let i = 0; i < selectedInflows.length; i++) {
    var inflowArray = [];
    if (selectedInflows[i] === undefined) {
      result.push(inflowArray);
      continue;
    }
    for (let j = 0; j < selectedInflows[i].length; j++) {
      if (selectedInflows[i][j].value === undefined) {
        continue;
      }
      inflowArray.push(selectedInflows[i][j].value.value);
    }
    result.push(inflowArray);
  }
  return result;
}

export function GetDamCapacity(selectedDam) {
  return DamCapLevelData.find((item) => item.Dam === selectedDam).Capacity || 0;
}

function getSegmentColorIdx(selectedInflowsArr, manualInflow, ctx, NaNLength) {
  let idx = 0;
  let totalmonthIdx = 0;
  if (selectedInflowsArr === undefined) {
    return idx;
  }
  for (let i = 0; i < selectedInflowsArr.length; i++) {
    let monthLength =
      parseInt(selectedInflowsArr[i].split(" ")[2]) || manualInflow[i]?.length;
    totalmonthIdx += monthLength;
    if (ctx.p0DataIndex - NaNLength + 1 < totalmonthIdx) {
      idx = i;
      break;
    }
  }
  return idx;
}

const getLineIndex = (label, selectedInflow) => {
  let lineIndex = -1;
  for (let i = 0; i < selectedInflow.length; i++) {
    if (
      selectedInflow[i] !== undefined &&
      (label === "Based on: " + selectedInflow[i].join(", ") ||
        label === selectedInflow[i].join(", "))
    ) {
      lineIndex = i;
      break;
    }
  }
  return lineIndex;
};

export const pointLabelSegment = (
  label,
  dataIndex,
  pointValue,
  NaNLength,
  selectedInflowsArr,
  selectedInflow,
  manualInflows
) => {
  let lineIndex = getLineIndex(label, selectedInflow);
  if (dataIndex >= NaNLength && lineIndex !== -1) {
    let idx = 0;
    let totalmonthIdx = 0;
    if (selectedInflowsArr[lineIndex] === undefined) {
      return idx;
    }
    for (let i = 0; i < selectedInflowsArr[lineIndex].length; i++) {
      let monthLength =
        parseInt(selectedInflowsArr[lineIndex][i].split(" ")[2]) ||
        manualInflows[lineIndex][i]?.length;
      totalmonthIdx += monthLength;
      if (dataIndex - NaNLength < totalmonthIdx) {
        idx = i;
        break;
      }
    }
    label = `Based on ${selectedInflow[lineIndex][idx]}`;
  }
  label += `: ${pointValue.toFixed(3)}`;
  return label;
};

const RenderGraph = observer(
  ({
    startActualYear,
    startActualMonth,
    startDatePrediction,
    endYearPrediction,
    endMonthPrediction,
    predictionPeriod,
    selectedInflow,
    selectedOutflow,
    manualOutflows,
    manualNamPanflows,
    manualInflows,
    selectedDam,
    rawWaterData,
    startForcastSignal,
  }) => {
    const [selectedInflowsArr, setSelectedInflowsArr] = useState([]);
    const [selectedOutflowsArr, setSelectedOutflowsArr] = useState([]);
    const [actualWaterData, setActualWaterData] = useState([
      { year: 0, month: 0, value: 0 },
    ]);
    useEffect(() => {
      setActualWaterData(rawWaterData);
    }, [rawWaterData]);
    const [startActualYearGraph, setStartActualYearGraph] =
      useState(startActualYear);
    const [startActualMonthGraph, setStartActualMonthGraph] =
      useState(startActualMonth);
    useEffect(() => {
      const startActualYearTemp = Math.max(
        startActualYear,
        rawWaterData[0]?.year
      );
      const startActualMonthTemp =
        startActualYearTemp >= rawWaterData[0]?.year
          ? startActualMonth
          : rawWaterData[0]?.month || 1;
      setStartActualYearGraph(startActualYearTemp);
      setStartActualMonthGraph(startActualMonthTemp);
    }, [actualWaterData, startActualYear, startActualMonth]);
    const datePrediction = new Date(startDatePrediction);
    // TODO: check if this is correct
    const startPredictionYear = datePrediction.getFullYear();
    const startPredictionMonth = datePrediction.getMonth() + 1;

    const [lines, setLines] = useState([
      { label: "", data: [] },
      { label: "", data: [] },
      { label: "", data: [] },
    ]);
    const [historicalInflowLine, setHistoricalInflowLine] = useState({
      data: [],
    });
    const [historicalOutflowLine, setHistoricalOutflowLine] = useState({
      data: [],
    });
    const [inflowLines, setInflowLines] = useState([
      { data: [] },
      { data: [] },
      { data: [] },
    ]);
    const [outflowLines, setOutflowLines] = useState([
      { data: [] },
      { data: [] },
      { data: [] },
    ]);
    const selectedDamRef = useRef(selectedDam);
    const [isLoading, setIsLoading] = useState(false);
    const handleCloseLoading = () => {
      setIsLoading(false);
    };
    const handleOpenLoading = () => {
      setIsLoading(true);
    };
    useEffect(() => {
      selectedDamRef.current = selectedDam;
    }, [selectedDam]);

    useMemo(() => {
      setSelectedInflowsArr(extractSelectedInflows(selectedInflow));
      for (let i = 0; i < selectedInflow.length; i++) {
        if (selectedInflow[i] === undefined || selectedInflow[i].length === 0) {
          // setSelectedInflowsArr((prev) => {
          //   let newInflows = [...prev];
          //   newInflows[i] = [""];
          //   return newInflows;
          // });
          setLines((prev) => {
            let newLines = [...prev];
            newLines[i] = { label: "", data: [] };
            return newLines;
          });
          setInflowLines((prev) => {
            let newInflowLines = [...prev];

            newInflowLines[i] = { label: "", data: [] };
            return newInflowLines;
          });
        }
      }
    }, [selectedInflow]);

    useMemo(() => {
      setSelectedOutflowsArr(extractSelectedInflows(selectedOutflow));
      for (let i = 0; i < selectedOutflow.length; i++) {
        if (
          selectedOutflow[i] === undefined ||
          selectedOutflow[i].length === 0
        ) {
          setLines((prev) => {
            let newLines = [...prev];
            newLines[i] = { label: "", data: [] };
            return newLines;
          });
          setOutflowLines((prev) => {
            let newOutflowLines = [...prev];
            newOutflowLines[i] = { label: "", data: [] };
            return newOutflowLines;
          });
        }
      }
    }, [selectedOutflow]);

    const onOptionChange = useCallback(async () => {
      const startIndex = rawWaterData.findIndex(
        (item) =>
          item.year === startActualYearGraph &&
          item.month === startActualMonthGraph
      );
      const endIndex = rawWaterData.findIndex(
        (item) =>
          item.year === endYearPrediction && item.month === endMonthPrediction
      );

      if (startIndex !== -1) {
        let temp = JSON.parse(JSON.stringify(rawWaterData));
        if (endIndex !== -1) {
          temp = temp.slice(startIndex, endIndex + 1);
        } else {
          temp = temp.slice(startIndex);
        }
        setActualWaterData(temp);
      }

      try {
        let historicalInflow = await fetchHistoricalInflow(
          selectedDam,
          startActualYearGraph,
          startActualMonthGraph
        );
        const endHistoricalIndex = historicalInflow.data.findIndex(
          (item) =>
            item.year === endYearPrediction && item.month === endMonthPrediction
        );
        if (endHistoricalIndex !== -1) {
          historicalInflow.data = historicalInflow.data.slice(
            0,
            endHistoricalIndex + 1
          );
        }
        setHistoricalInflowLine(historicalInflow);
      } catch (error) {
        console.error("Error fetching historical inflow:", error);
      }

      try {
        let historicalOutflow = await fetchHistoricalOutflow(
          selectedDam,
          startActualYearGraph,
          startActualMonthGraph
        );
        const endHistoricalIndex = historicalOutflow.data.findIndex(
          (item) =>
            item.year === endYearPrediction && item.month === endMonthPrediction
        );
        if (endHistoricalIndex !== -1) {
          historicalOutflow.data = historicalOutflow.data.slice(
            0,
            endHistoricalIndex + 1
          );
        }
        setHistoricalOutflowLine(historicalOutflow);
      } catch (error) {
        console.error("Error fetching historical inflow:", error);
      }

      // globalStore.stopLoad()
    }, [
      startActualYearGraph,
      startActualMonthGraph,
      endYearPrediction,
      endMonthPrediction,
      selectedDam,
      rawWaterData,
    ]);

    useEffect(() => {
      onOptionChange();
    }, [
      startActualYearGraph,
      startActualMonthGraph,
      endYearPrediction,
      endMonthPrediction,
      selectedDam,
      rawWaterData,
      onOptionChange,
    ]);

    const LoopCheck = async () => {
      for (let i = 0; i < selectedInflowsArr.length; i++) {
        if (
          selectedInflowsArr[i][0] !== "" &&
          selectedInflowsArr[i][0] !== undefined
        ) {
          let Line = { label: "", data: [] };
          let inflowLine = { data: [] };
          let outflowLine = { data: [] };
          let orginValue = 0;
          let startYear = startActualYearGraph;
          let startMonth = startActualMonthGraph;
          let index = actualWaterData.findIndex(
            (item) =>
              item.year === startPredictionYear &&
              item.month === startPredictionMonth
          );
          let inflowIndex = historicalInflowLine.data.findIndex(
            (item) =>
              item.year === startPredictionYear &&
              item.month === startPredictionMonth
          );
          let outflowIndex = historicalOutflowLine.data.findIndex(
            (item) =>
              item.year === startPredictionYear &&
              item.month === startPredictionMonth
          );
          if (index === -1) {
            index = actualWaterData.length;
          }
          while (
            startYear < startPredictionYear ||
            (startYear === startPredictionYear &&
              startMonth <= startPredictionMonth)
          ) {
            if (startMonth > 12) {
              startMonth = 1;
              startYear++;
            }
            if (
              startYear === startPredictionYear &&
              startMonth === startPredictionMonth
            ) {
              break;
            }
            // add last actual water capacity level to each Scenario lines
            if (
              startYear === actualWaterData[index - 1].year &&
              startMonth === actualWaterData[index - 1].month
            ) {
              let originData = actualWaterData[index - 1];
              orginValue = originData.value;
              Line.data.push({
                year: originData.year,
                month: originData.month,
                value: originData.value,
              });
              // Add start prediction point to Inflow Line
              if (historicalInflowLine.data[inflowIndex - 1] !== undefined) {
                inflowLine.data.push({
                  year: startYear,
                  month: startMonth,
                  value: historicalInflowLine.data[inflowIndex - 1].value,
                });
              } else if (historicalInflowLine.data.length !== 0) {
                inflowLine.data.push({
                  year: startYear,
                  month: startMonth,
                  value:
                    historicalInflowLine.data[
                      historicalInflowLine.data.length - 1
                    ].value,
                });
              }
              if (
                selectedOutflowsArr[i][0] !== "" &&
                selectedOutflowsArr[i][0] !== undefined
              ) {
                // Add start prediction point to Outflow Line
                if (
                  historicalOutflowLine.data[outflowIndex - 1] !== undefined
                ) {
                  outflowLine.data.push({
                    year: startYear,
                    month: startMonth,
                    value: historicalOutflowLine.data[outflowIndex - 1].value,
                  });
                } else if (historicalOutflowLine.data.length !== 0) {
                  outflowLine.data.push({
                    year: startYear,
                    month: startMonth,
                    value:
                      historicalOutflowLine.data[
                        historicalOutflowLine.data.length - 1
                      ].value,
                  });
                }
              }
            } else {
              Line.data.push({
                year: startYear,
                month: startMonth,
                value: NaN,
              });
              inflowLine.data.push({
                year: startYear,
                month: startMonth,
                value: NaN,
              });
              outflowLine.data.push({
                year: startYear,
                month: startMonth,
                value: NaN,
              });
            }
            startMonth++;
          }
          const response = await FetchInflowData(
            selectedDam,
            selectedInflowsArr[i],
            manualInflows[i],
            startPredictionYear,
            startPredictionMonth,
            startActualYearGraph,
            startActualMonthGraph
          );
          const response_outflow = await FetchOutflowData(
            selectedDam,
            selectedOutflowsArr[i],
            manualOutflows[i],
            startPredictionYear,
            startPredictionMonth,
            startActualYearGraph,
            startActualMonthGraph
          );
          inflowLine.data.push(...response.inflows.data);
          outflowLine.data.push(...response_outflow.inflows.data);
          AddInflowToLine(
            Line,
            response,
            orginValue,
            response_outflow,
            manualNamPanflows[i],
            selectedDam
          );

          let endIndex = Line.data.findIndex(
            (item) =>
              item.year === endYearPrediction &&
              item.month === endMonthPrediction
          );
          let endInflowIndex = inflowLine.data.findIndex(
            (item) =>
              item.year === endYearPrediction &&
              item.month === endMonthPrediction
          );
          let endOutflowIndex = outflowLine.data.findIndex(
            (item) =>
              item.year === endYearPrediction &&
              item.month === endMonthPrediction
          );
          if (endIndex !== -1) {
            Line.data = Line.data.slice(0, endIndex + 1);
          }
          if (endInflowIndex !== -1) {
            inflowLine.data = inflowLine.data.slice(0, endInflowIndex + 1);
          }
          if (endOutflowIndex !== -1) {
            outflowLine.data = outflowLine.data.slice(0, endOutflowIndex + 1);
          }
          setLines((prev) => {
            let newLines = [...prev];
            newLines[i] = Line;
            return newLines;
          });
          setInflowLines((prev) => {
            let newInflowLines = [...prev];
            newInflowLines[i] = inflowLine;
            return newInflowLines;
          });
          setOutflowLines((prev) => {
            let newOutflowLines = [...prev];
            newOutflowLines[i] = outflowLine;
            return newOutflowLines;
          });
        }
      }
    };
    // Process inflow data
    useEffect(() => {
      async function fetchData() {
        handleOpenLoading();
        try {
          await LoopCheck();
        } catch (error) {
          console.error("Error during LoopCheck:", error);
        } finally {
          handleCloseLoading();
        }
      }
      fetchData();
    }, [
      startForcastSignal,
      actualWaterData,
      predictionPeriod,
      // historicalInflowLine,
      // historicalOutflowLine,
    ]);
    // const [maxLengthLine, setMaxLengthLine] = useState(lines[0]);
    const [maxLengthInflowLine, setMaxLengthInflowLine] =
      useState(historicalInflowLine);
    const [initializeLineLabel, setInitializeLineLabel] = useState(
      actualWaterData.map(
        (data) => data.year.toString() + "/" + data.month.toString()
      )
    );
    const [warningLevelLines, setWarningLevelLines] = useState([{}, {}, {}]);
    useEffect(() => {
      // Graph setting
      let maxLengthLine = lines.reduce((max, line) => {
        if (line.data.length === 0) {
          return max;
        }
        return line.data.length > max.data.length ? line : max;
      }, lines[0]);
      let maxLengthInflowLine = [historicalInflowLine, ...inflowLines].reduce(
        (max, line) => {
          try {
            if (line.data.length === 0) {
              return max;
            }
            return line.data.length > max.data.length ? line : max;
          } catch {
            console.log(`max ${max} with line ${line}`);
            return 0;
          }
        },
        historicalInflowLine
      );
      let initializeLineLabel =
        maxLengthLine.data.length > actualWaterData.length
          ? maxLengthLine.data.map(
              (data) => data.year.toString() + "/" + data.month.toString()
            )
          : actualWaterData.map(
              (data) => data.year.toString() + "/" + data.month.toString()
            );
      let warningLevelLines = GetWarningLevelLines(
        selectedDam,
        initializeLineLabel
      );
      setWarningLevelLines(warningLevelLines);
      setMaxLengthInflowLine(maxLengthInflowLine);
      setInitializeLineLabel(initializeLineLabel);
    }, [
      actualWaterData,
      selectedInflowsArr,
      lines,
      startActualYearGraph,
      startActualMonthGraph,
      historicalInflowLine,
    ]);

    const warningLabelDarkRed = {
      type: "label",
      yValue: (ctx) => {
        let lowestPoint =
          warningLevelLines[0].data !== undefined
            ? Math.min(...warningLevelLines[0].data)
            : 0;
        return lowestPoint;
      },
      content: "ปริมาณกักเก็บต่ำสุด",
      xValue: 0.5,
      textAlign: "left",
      position: "start",
      color: "white",
      textStrokeColor: "black",
      textStrokeWidth: 2,
      padding: 0,
    };
    const warningLevelRed = {
      type: "label",
      yValue: (ctx) => {
        let lowestPoint =
          warningLevelLines[1].data !== undefined
            ? Math.min(...warningLevelLines[1].data)
            : 0;
        return lowestPoint;
      },
      content: "เตือนขาดแคลนน้ำ",
      xValue: 0.5,
      textAlign: "left",
      position: "start",
      color: "white",
      textStrokeColor: "black",
      textStrokeWidth: 2,
      padding: 0,
    };
    const warningLevelYellow = {
      type: "label",
      yValue: (ctx) => {
        let lowestPoint =
          warningLevelLines[2].data !== undefined
            ? Math.min(...warningLevelLines[2].data)
            : 0;
        return lowestPoint;
      },
      content: "เฝ้าระวัง",
      xValue: 0.5,
      textAlign: "left",
      position: "start",
      color: "white",
      textStrokeColor: "black",
      textStrokeWidth: 2,
      padding: 0,
    };
    const damCapacityAnnotation = {
      type: "line",
      borderColor: "black",
      borderWidth: 3,
      label: {
        display: true,
        backgroundColor: "black",
        borderColor: "black",
        borderRadius: 20,
        borderWidth: 2,
        content: (ctx) => {
          return "ความจุอ่าง: " + GetDamCapacity(selectedDam).toFixed(3);
        },
        rotation: "auto",
        position: "start",
      },
      scaleID: "y",
      value: GetDamCapacity(selectedDam),
    };
    const yearSeparator = {
      afterDraw: function (chart) {
        const {
          ctx,
          scales: { x, y },
          data: { labels },
        } = chart;
        for (let i = 0; i < labels.length; i++) {
          let yStart = y.getPixelForValue(0);
          let yEnd = y.getPixelForValue(GetDamCapacity(selectedDamRef.current));
          if (labels[i].includes("/12")) {
            const xValue = x.getPixelForValue(labels[i]);
            ctx.save();
            ctx.beginPath();
            ctx.strokeStyle = "rgba(0, 0, 0, 0.3)";
            ctx.lineWidth = 2;
            ctx.setLineDash([10, 10]);
            ctx.moveTo(xValue, yStart);
            ctx.lineTo(xValue, yEnd);
            ctx.stroke();
            ctx.restore();
          }
        }
      },
    };
    return (
      <Grid height="100vh">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container alignItems="end" justifyContent="end">
          <DownloadButton
            // "water_data_{dam}_{date}.csv"
            // {date} with format YYYY-MM-DD
            filename={
              "water_data_" + selectedDam + "_" + new Date().toISOString()
            }
            capData={actualWaterData}
            capPrediction={lines}
            inflowData={historicalInflowLine}
            inflowPrediction={inflowLines}
            outflowData={historicalOutflowLine}
            outflowPrediction={outflowLines}
            selectedDam={selectedDam}
            selectedInflow={selectedInflow}
            selectedInflowsArr={selectedInflowsArr}
            selectedOutflow={selectedOutflow}
            selectedOutflowsArr={selectedOutflowsArr}
            manualInflows={manualInflows}
            manualOutflows={manualOutflows}
          />
        </Grid>
        <Grid xs={12} sx={{ height: "40%" }}>
          <Line
            data={{
              labels: initializeLineLabel,
              datasets: [
                {
                  label: "Actual Volume",
                  data: actualWaterData.map((data) => data.value),
                  borderColor: "rgba(0, 0, 0)",
                  backgroundColor: "rgba(0, 0, 0)",
                },
                {
                  // label: Lines[0].label,
                  label:
                    selectedInflow[0] === undefined ||
                    selectedInflow[0].length === 0
                      ? ""
                      : "Based on: " + selectedInflow[0].join(", "),
                  data:
                    lines[0].data.map((data) => {
                      return data.value;
                    }) || [],
                  borderColor: line1DarkColor,
                  backgroundColor: line1DarkColor,
                  segment: {
                    borderColor: (ctx) => {
                      const colors = [line1DarkColor, line1LightColor];
                      const NaNLength = lines[0].data.filter((data) => {
                        return isNaN(data.value);
                      }).length;
                      if (ctx.p0DataIndex >= NaNLength) {
                        let idx = getSegmentColorIdx(
                          selectedInflowsArr[0],
                          manualInflows[0],
                          ctx,
                          NaNLength + 1
                        );
                        return colors[idx % colors.length];
                      }
                    },
                    // borderDash: (ctx) => skipped(ctx, [6, 6]),
                  },
                  spanGaps: true,
                },
                {
                  label:
                    selectedInflow[1] === undefined ||
                    selectedInflow[1].length === 0
                      ? ""
                      : "Based on: " + selectedInflow[1].join(", "),
                  data: lines[1].data.map((data) => data.value),
                  borderColor: line2DarkColor,
                  backgroundColor: line2DarkColor,
                  segment: {
                    borderColor: (ctx) => {
                      const colors = [line2DarkColor, line2LightColor];
                      const NaNLength = lines[1].data.filter((data) => {
                        return isNaN(data.value);
                      }).length;
                      if (ctx.p0DataIndex >= NaNLength) {
                        let idx = getSegmentColorIdx(
                          selectedInflowsArr[1],
                          manualInflows[1],
                          ctx,
                          NaNLength + 1
                        );
                        return colors[idx % colors.length];
                      }
                    },
                    // borderDash: (ctx) => skipped(ctx, [6, 6]),
                  },
                  spanGaps: true,
                },
                {
                  label:
                    selectedInflow[2] === undefined ||
                    selectedInflow[2].length === 0
                      ? ""
                      : "Based on: " + selectedInflow[2].join(", "),
                  data: lines[2].data.map((data) => data.value),
                  borderColor: line3DarkColor,
                  backgroundColor: line3DarkColor,
                  segment: {
                    borderColor: (ctx) => {
                      const colors = [line3DarkColor, line3LightColor];
                      const NaNLength = lines[2].data.filter((data) => {
                        return isNaN(data.value);
                      }).length;
                      if (ctx.p0DataIndex >= NaNLength) {
                        let idx = getSegmentColorIdx(
                          selectedInflowsArr[2],
                          manualInflows[2],
                          ctx,
                          NaNLength + 1
                        );
                        return colors[idx % colors.length];
                      }
                    },
                    // borderDash: (ctx) => skipped(ctx, [6, 6]),
                  },
                  spanGaps: true,
                },
              ].concat(warningLevelLines),
            }}
            options={{
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: "ปี/เดือน",
                  },
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: "ปริมาณน้ำ (ล้านลูกบาศก์เมตร)",
                  },
                },
              },
              // Export chart to png
              // animation: {
              //   onComplete: function ({ chart }) {
              //     console.log(chart);
              //     console.log(chart.toBase64Image());
              //   },
              // },

              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return pointLabelSegment(
                        context.dataset.label,
                        context.dataIndex,
                        context.raw,
                        actualWaterData.length,
                        selectedInflowsArr,
                        selectedInflow,
                        manualInflows
                      );
                    },
                  },
                },
                legend: {
                  display: true,
                  labels: {
                    boxHeight: 10,
                    boxWidth: 10,
                    filter: function (legendItem) {
                      return (
                        legendItem.datasetIndex <= 3 && legendItem.text !== ""
                      );
                    },
                  },
                },
                annotation: {
                  annotations: {
                    damCapacityAnnotation: damCapacityAnnotation,
                    warningLabelDarkRed: warningLabelDarkRed,
                    warningLevelRed: warningLevelRed,
                    warningLevelYellow: warningLevelYellow,
                  },
                },
                title: {
                  display: true,
                  text: `ปริมาณน้ำ${DamName[selectedDam]} จ.ระยอง`,
                  font: {
                    size: 16,
                    weight: "bold",
                  },
                },
              },
              maintainAspectRatio: false,
              // aspectRatio: 2.75,
            }}
            plugins={[yearSeparator]}
            style={{ width: "100vh" }}
            // height="100vh"
          />
        </Grid>
        <Grid xs={12} sx={{ height: "40%" }}>
          <Line
            data={{
              labels: maxLengthInflowLine.data.map(
                (data) => data.year.toString() + "/" + data.month.toString()
              ),
              datasets: [
                {
                  label: "Historical Inflow",
                  data: historicalInflowLine.data.map((data) => data.value),
                  borderColor: "black",
                  backgroundColor: "black",
                },
                {
                  label:
                    selectedInflow[0] === undefined ||
                    selectedInflow[0].length === 0
                      ? ""
                      : "Inflow: " + selectedInflow[0].join(", "),
                  data: inflowLines[0].data.map((data) => data.value),
                  borderColor: line1DarkColor,
                  backgroundColor: line1DarkColor,
                  segment: {
                    borderColor: (ctx) => {
                      const colors = [line1DarkColor, line1LightColor];
                      let idx = getSegmentColorIdx(
                        selectedInflowsArr[0],
                        manualInflows[0],
                        ctx,
                        actualWaterData.length
                      );
                      return colors[idx % colors.length];
                    },
                  },
                },
                {
                  // label: Lines[1].label,
                  label:
                    selectedInflow[1] === undefined ||
                    selectedOutflow[1].length === 0
                      ? ""
                      : "Inflow: " + selectedInflow[1].join(", "),
                  data: inflowLines[1].data.map((data) => data.value),
                  borderColor: line2DarkColor,
                  backgroundColor: line2DarkColor,
                  segment: {
                    borderColor: (ctx) => {
                      const colors = [line2DarkColor, line2LightColor];
                      let idx = getSegmentColorIdx(
                        selectedInflowsArr[1],
                        manualInflows[1],
                        ctx,
                        actualWaterData.length
                      );
                      return colors[idx % colors.length];
                    },
                  },
                },
                {
                  // label: Lines[2].label,
                  label:
                    selectedInflow[2] === undefined ||
                    selectedOutflow[2].length === 0
                      ? ""
                      : "Inflow: " + selectedInflow[2].join(", "),
                  data: inflowLines[2].data.map((data) => data.value),
                  borderColor: line3DarkColor,
                  backgroundColor: line3DarkColor,
                  segment: {
                    borderColor: (ctx) => {
                      const colors = [line3DarkColor, line3LightColor];
                      let idx = getSegmentColorIdx(
                        selectedInflowsArr[2],
                        manualInflows[2],
                        ctx,
                        actualWaterData.length
                      );
                      return colors[idx % colors.length];
                    },
                  },
                },
                {
                  label: "Historical Outflow",
                  data: historicalOutflowLine.data.map((data) => data.value),
                  borderColor: "rgba(0,0,0,0.5)",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  borderDash: [5, 5],
                },
                {
                  label:
                    selectedOutflow[0] === undefined ||
                    selectedOutflow[0].length === 0
                      ? ""
                      : "Outflow: " + selectedOutflow[0].join(", "),
                  data: outflowLines[0].data.map((data) => data.value),
                  borderColor: line1DarkColor,
                  backgroundColor: line1LightColor,
                  borderDash: [5, 5],
                  segment: {
                    borderColor: (ctx) => {
                      const colors = [line1DarkColor, line1LightColor];
                      let idx = getSegmentColorIdx(
                        selectedOutflowsArr[0],
                        manualOutflows[0],
                        ctx,
                        actualWaterData.length
                      );
                      return colors[idx % colors.length];
                    },
                  },
                },
                {
                  label:
                    selectedOutflow[1] === undefined ||
                    selectedOutflow[1].length === 0
                      ? ""
                      : "Outflow: " + selectedOutflow[1].join(", "),
                  data: outflowLines[1].data.map((data) => data.value),
                  borderColor: line2DarkColor,
                  backgroundColor: line2LightColor,
                  borderDash: [5, 5],
                  segment: {
                    borderColor: (ctx) => {
                      const colors = [line2DarkColor, line2LightColor];
                      let idx = getSegmentColorIdx(
                        selectedOutflowsArr[1],
                        manualOutflows[1],
                        ctx,
                        actualWaterData.length
                      );
                      return colors[idx % colors.length];
                    },
                  },
                },
                {
                  label:
                    selectedOutflow[2] === undefined ||
                    selectedOutflow[2].length === 0
                      ? ""
                      : "Outflow: " + selectedOutflow[2].join(", "),
                  data: outflowLines[2].data.map((data) => data.value),
                  borderColor: line3DarkColor,
                  backgroundColor: line3LightColor,
                  borderDash: [5, 5],
                  segment: {
                    borderColor: (ctx) => {
                      const colors = [line3DarkColor, line3LightColor];
                      let idx = getSegmentColorIdx(
                        selectedOutflowsArr[2],
                        manualOutflows[2],
                        ctx,
                        actualWaterData.length
                      );
                      return colors[idx % colors.length];
                    },
                  },
                },
              ],
            }}
            options={{
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: "ปี/เดือน",
                  },
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: "ปริมาณน้ำเข้า (ล้านลูกบาศก์เมตร)",
                  },
                },
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return pointLabelSegment(
                        context.dataset.label,
                        context.dataIndex,
                        context.raw,
                        actualWaterData.length,
                        selectedInflowsArr,
                        selectedInflow,
                        manualInflows
                      );
                    },
                  },
                },
                legend: {
                  display: true,
                  labels: {
                    boxHeight: 10,
                    boxWidth: 10,
                    filter: function (legendItem) {
                      return (
                        legendItem.datasetIndex <= 7 && legendItem.text !== ""
                      );
                    },
                  },
                },
                title: {
                  display: true,
                  text: `ปริมาณน้ำเข้า (Inflow) ${DamName[selectedDam]} จ.ระยอง`,
                  font: {
                    size: 16,
                    weight: "bold",
                  },
                },
              },
              // aspectRatio: 2.75,
              maintainAspectRatio: false,
            }}
            plugins={[yearSeparator]}
            style={{ width: "100vh" }}
          />
        </Grid>
        <p className="graph-note" style={{ margin: 0 }}>
          <b>หมายเหตุ:</b> กราฟคาดการณ์ปริมาณน้ำคำนวณจากปริมาณน้ำ ณ
          สิ้นเดือนจากกรมชลประทาน [
          <a href="https://app.rid.go.th/reservoir/">
            https://app.rid.go.th/reservoir/
          </a>
          ] รวมกับผลต่างระหว่างคาดการณ์ปริมาณน้ำเข้า (Inflow)
          และคาดการณ์ปริมาณน้ำออก (Outflow)
        </p>
        <p className="graph-note" style={{ margin: 0 }}>
          <b>ดาวน์โหลดเอกสาร:</b> Manual และ Model Documentation{" "}
          <a href="https://scgo365-my.sharepoint.com/:f:/g/personal/sutheepr_scg_com/EklaexUqL75EumNqjRBoMH8BIvOe5d9nNaUdVJMFH3rNpQ?e=hUYUv4">
            ที่นี่
          </a>
        </p>
      </Grid>
    );
  }
);

export default RenderGraph;
