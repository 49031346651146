const WarningLevelData = [
  {
    dam: "DK",
    warningLevel: [
      {
        Reserviour: "DK",
        Month: 1,
        Dark_Red: 8.876,
        Red: 30.8,
        Yellow: 61.6,
      },
      {
        Reserviour: "DK",
        Month: 2,
        Dark_Red: 8.876,
        Red: 25.2,
        Yellow: 56.0,
      },
      {
        Reserviour: "DK",
        Month: 3,
        Dark_Red: 8.876,
        Red: 22.4,
        Yellow: 50.4,
      },
      {
        Reserviour: "DK",
        Month: 4,
        Dark_Red: 8.876,
        Red: 19.6,
        Yellow: 47.6,
      },
      {
        Reserviour: "DK",
        Month: 5,
        Dark_Red: 8.876,
        Red: 19.6,
        Yellow: 47.6,
      },
      {
        Reserviour: "DK",
        Month: 6,
        Dark_Red: 8.876,
        Red: 22.4,
        Yellow: 47.6,
      },
      {
        Reserviour: "DK",
        Month: 7,
        Dark_Red: 8.876,
        Red: 25.2,
        Yellow: 50.4,
      },
      {
        Reserviour: "DK",
        Month: 8,
        Dark_Red: 8.876,
        Red: 30.8,
        Yellow: 53.2,
      },
      {
        Reserviour: "DK",
        Month: 9,
        Dark_Red: 8.876,
        Red: 33.6,
        Yellow: 58.8,
      },
      {
        Reserviour: "DK",
        Month: 10,
        Dark_Red: 8.876,
        Red: 33.6,
        Yellow: 64.4,
      },
      {
        Reserviour: "DK",
        Month: 11,
        Dark_Red: 8.876,
        Red: 33.6,
        Yellow: 64.4,
      },
      {
        Reserviour: "DK",
        Month: 12,
        Dark_Red: 8.876,
        Red: 33.6,
        Yellow: 61.6,
      },
    ],
  },
  {
    dam: "KY",
    warningLevel: [
      {
        Reserviour: "KY",
        Month: 1,
        Dark_Red: 4.438,
        Red: 15.4,
        Yellow: 30.8,
      },
      {
        Reserviour: "KY",
        Month: 2,
        Dark_Red: 4.438,
        Red: 12.6,
        Yellow: 28.0,
      },
      {
        Reserviour: "KY",
        Month: 3,
        Dark_Red: 4.438,
        Red: 11.2,
        Yellow: 25.2,
      },
      {
        Reserviour: "KY",
        Month: 4,
        Dark_Red: 4.438,
        Red: 9.8,
        Yellow: 23.8,
      },
      {
        Reserviour: "KY",
        Month: 5,
        Dark_Red: 4.438,
        Red: 9.8,
        Yellow: 23.8,
      },
      {
        Reserviour: "KY",
        Month: 6,
        Dark_Red: 4.438,
        Red: 11.2,
        Yellow: 23.8,
      },
      {
        Reserviour: "KY",
        Month: 7,
        Dark_Red: 4.438,
        Red: 12.6,
        Yellow: 25.2,
      },
      {
        Reserviour: "KY",
        Month: 8,
        Dark_Red: 4.438,
        Red: 15.4,
        Yellow: 26.6,
      },
      {
        Reserviour: "KY",
        Month: 9,
        Dark_Red: 4.438,
        Red: 16.8,
        Yellow: 29.4,
      },
      {
        Reserviour: "KY",
        Month: 10,
        Dark_Red: 4.438,
        Red: 16.8,
        Yellow: 32.2,
      },
      {
        Reserviour: "KY",
        Month: 11,
        Dark_Red: 4.438,
        Red: 16.8,
        Yellow: 32.2,
      },
      {
        Reserviour: "KY",
        Month: 12,
        Dark_Red: 4.438,
        Red: 16.8,
        Yellow: 30.8,
      },
    ],
  },
  {
    dam: "NPL",
    warningLevel: [
      {
        Reserviour: "NPL",
        Month: 1,
        Dark_Red: 18.386,
        Red: 63.8,
        Yellow: 127.6,
      },
      {
        Reserviour: "NPL",
        Month: 2,
        Dark_Red: 18.386,
        Red: 52.2,
        Yellow: 116.0,
      },
      {
        Reserviour: "NPL",
        Month: 3,
        Dark_Red: 18.386,
        Red: 46.4,
        Yellow: 104.4,
      },
      {
        Reserviour: "NPL",
        Month: 4,
        Dark_Red: 18.386,
        Red: 40.6,
        Yellow: 98.6,
      },
      {
        Reserviour: "NPL",
        Month: 5,
        Dark_Red: 18.386,
        Red: 40.6,
        Yellow: 98.6,
      },
      {
        Reserviour: "NPL",
        Month: 6,
        Dark_Red: 18.386,
        Red: 46.4,
        Yellow: 98.6,
      },
      {
        Reserviour: "NPL",
        Month: 7,
        Dark_Red: 18.386,
        Red: 52.2,
        Yellow: 104.4,
      },
      {
        Reserviour: "NPL",
        Month: 8,
        Dark_Red: 18.386,
        Red: 63.8,
        Yellow: 110.2,
      },
      {
        Reserviour: "NPL",
        Month: 9,
        Dark_Red: 18.386,
        Red: 69.6,
        Yellow: 121.8,
      },
      {
        Reserviour: "NPL",
        Month: 10,
        Dark_Red: 18.386,
        Red: 69.6,
        Yellow: 133.4,
      },
      {
        Reserviour: "NPL",
        Month: 11,
        Dark_Red: 18.386,
        Red: 69.6,
        Yellow: 133.4,
      },
      {
        Reserviour: "NPL",
        Month: 12,
        Dark_Red: 18.386,
        Red: 69.6,
        Yellow: 127.6,
      },
    ],
  },
  {
    dam: "PS",
    warningLevel: [
      {
        Reserviour: "PS",
        Month: 1,
        Dark_Red: 20.0,
        Red: 100.0,
        Yellow: 250.0,
      },
      {
        Reserviour: "PS",
        Month: 2,
        Dark_Red: 20.0,
        Red: 70.0,
        Yellow: 240.0,
      },
      {
        Reserviour: "PS",
        Month: 3,
        Dark_Red: 20.0,
        Red: 40.0,
        Yellow: 220.0,
      },
      {
        Reserviour: "PS",
        Month: 4,
        Dark_Red: 20.0,
        Red: 40.0,
        Yellow: 200.0,
      },
      {
        Reserviour: "PS",
        Month: 5,
        Dark_Red: 20.0,
        Red: 40.0,
        Yellow: 200.0,
      },
      {
        Reserviour: "PS",
        Month: 6,
        Dark_Red: 20.0,
        Red: 40.0,
        Yellow: 200.0,
      },
      {
        Reserviour: "PS",
        Month: 7,
        Dark_Red: 20.0,
        Red: 40.0,
        Yellow: 200.0,
      },
      {
        Reserviour: "PS",
        Month: 8,
        Dark_Red: 20.0,
        Red: 60.0,
        Yellow: 220.0,
      },
      {
        Reserviour: "PS",
        Month: 9,
        Dark_Red: 20.0,
        Red: 80.0,
        Yellow: 240.0,
      },
      {
        Reserviour: "PS",
        Month: 10,
        Dark_Red: 20.0,
        Red: 90.0,
        Yellow: 250.0,
      },
      {
        Reserviour: "PS",
        Month: 11,
        Dark_Red: 20.0,
        Red: 90.0,
        Yellow: 250.0,
      },
      {
        Reserviour: "PS",
        Month: 12,
        Dark_Red: 20.0,
        Red: 90.0,
        Yellow: 250.0,
      },
    ],
  },
  {
    dam: "DK+KY+NPL",
    warningLevel: [
      {
        Reserviour: "DK+KY+NPL",
        Month: 1,
        Dark_Red: 31.7,
        Red: 110,
        Yellow: 220,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 2,
        Dark_Red: 31.7,
        Red: 90,
        Yellow: 200,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 3,
        Dark_Red: 31.7,
        Red: 80,
        Yellow: 180,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 4,
        Dark_Red: 31.7,
        Red: 70,
        Yellow: 170,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 5,
        Dark_Red: 31.7,
        Red: 70,
        Yellow: 170,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 6,
        Dark_Red: 31.7,
        Red: 80,
        Yellow: 170,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 7,
        Dark_Red: 31.7,
        Red: 90,
        Yellow: 180.0,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 8,
        Dark_Red: 31.7,
        Red: 110.0,
        Yellow: 190.0,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 9,
        Dark_Red: 31.7,
        Red: 120.0,
        Yellow: 210.0,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 10,
        Dark_Red: 31.7,
        Red: 120.0,
        Yellow: 230.0,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 11,
        Dark_Red: 31.7,
        Red: 120.0,
        Yellow: 230.0,
      },
      {
        Reserviour: "DK+KY+NPL",
        Month: 12,
        Dark_Red: 31.7,
        Red: 120.0,
        Yellow: 220.0,
      },
    ],
  },
];

export default WarningLevelData;
