import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid, Box, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import { GetYearRangeWithDamName } from "../../db/water_data";
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export function ActualTimeSelector({
  year,
  setYear,
  month,
  setMonth,
  selectedDam,
  waterData,
}) {
  const yearList = GetYearRangeWithDamName(selectedDam, waterData);
  const yearItems = yearList.map((year) => {
    return (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  });
  const monthItems = monthList.map((month) => {
    return (
      <MenuItem key={month} value={month}>
        {month}
      </MenuItem>
    );
  });
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }} alignItems="left" display="flex">
      <Grid container>
        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle1" component="div">
            Actual Start
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="actual-year-label">ปี</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={year}
              label="Year"
              onChange={handleYearChange}
            >
              {yearItems}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="actual-month-label">เดือน</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={month}
              label="Month"
              onChange={handleMonthChange}
            >
              {monthItems}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}

export function PredictionTimeSelector({
  startYear,
  setStartYear,
  startMonth,
  setStartMonth,
  endYear,
  setEndYear,
  endMonth,
  setEndMonth,
  lastRawWaterYear,
  selectedDam,
  waterData,
}) {
  const yearList = GetYearRangeWithDamName(selectedDam, waterData);
  const lastYear = yearList[yearList.length - 1];
  const endYearList = [...yearList, lastRawWaterYear + 1, lastRawWaterYear + 2];
  const yearItems = yearList.map((startYear) => {
    return (
      <MenuItem key={startYear} value={startYear}>
        {startYear}
      </MenuItem>
    );
  });
  const endYearItems = endYearList.map((ey) => {
    return (
      <MenuItem key={ey} value={ey}>
        {ey}
      </MenuItem>
    );
  });
  const monthItems = monthList.map((month) => {
    return (
      <MenuItem key={month} value={month}>
        {month}
      </MenuItem>
    );
  });

  return (
    <Box sx={{ minWidth: 120 }} alignItems="left" display="flex">
      <Grid container>
        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle1" component="div">
            Prediction Start
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">ปี</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={startYear}
              label="Year"
              onChange={(e) => setStartYear(e.target.value)}
            >
              {yearItems}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="Month-select-label">เดือน</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={startMonth}
              label="Month"
              onChange={(e) => {
                setStartMonth(e.target.value);
              }}
            >
              {monthItems}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle1" component="div">
            Prediction End
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">ปี</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={endYear}
              label="EndYear"
              onChange={(e) => setEndYear(e.target.value)}
            >
              {endYearItems}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="Month-select-label">เดือน</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={endMonth}
              label="EndMonth"
              onChange={(e) => setEndMonth(e.target.value)}
            >
              {monthItems}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}

// export { ActualTimeSelector };
