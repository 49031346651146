import {
  fetchHistoricalInflow,
  fetchInflowPredictionData,
  fetchOutflowData,
} from "../../db/apis";
import { GetDamCapacity } from "./graph";
import axios from "axios";

function filteredInflowData(
  inflowName,
  selectedDamActualData,
  startPredictionYear,
  startPredictionMonth,
  manualInflow,
  monthLength
) {
  let predictionInflowData;
  if (inflowName === "Manual") {
    // Case manual inflow
    predictionInflowData = {
      label: "manual",
      data: generateManualInflowData(
        manualInflow,
        monthLength,
        startPredictionYear,
        startPredictionMonth
      ),
    };
  } else if (
    // Case average & 2540 inflows
    inflowName.search("2540") !== -1 ||
    inflowName.search("Average") !== -1
  ) {
    predictionInflowData = generate2540InflowData(
      startPredictionYear,
      startPredictionMonth,
      monthLength,
      inflowName,
      selectedDamActualData.inflows
    );
  } else {
    // Case model prediction inflow
    predictionInflowData = JSON.parse(
      JSON.stringify(
        getInflowDataWithLabelName(selectedDamActualData.inflows, inflowName)
      )
    );
  }
  const startIndex = predictionInflowData.data.findIndex(
    (item) =>
      item.year === startPredictionYear && item.month === startPredictionMonth
  );
  if (startIndex !== -1) {
    predictionInflowData.data = predictionInflowData.data
      .slice(startIndex)
      .slice(0, monthLength);
  }
  return predictionInflowData;
}

function getInflowDataWithLabelName(inflows, label) {
  for (let i = 0; i < inflows.length; i++) {
    if (inflows[i].label === label) {
      return inflows[i];
    }
  }
  return [];
}

function generateManualInflowData(
  manualInflow,
  monthSplit,
  startPredictionYear,
  startPredictionMonth
) {
  let manualInflowData = [];
  let startYear = startPredictionYear;
  let startMonth = startPredictionMonth;
  for (let i = 0; i < manualInflow.length; i++) {
    if (startMonth > 12) {
      startMonth = 1;
      startYear++;
    }
    manualInflowData.push({
      year: startYear,
      month: startMonth,
      value: manualInflow[i],
    });
    startMonth += 1;
  }
  return manualInflowData;
}

function generate2540InflowData(
  startPredictionYear,
  startPredictionMonth,
  monthLength,
  inflowName,
  inflows
) {
  let inflow2540 = {
    label: inflowName,
    data: [],
  };
  const inflow2540Master = getInflowDataWithLabelName(inflows, inflowName);

  let startYear = startPredictionYear;
  let startMonth = startPredictionMonth;
  for (let i = 0; i < monthLength; i++) {
    if (startMonth > 12) {
      startMonth = 1;
      startYear += 1;
    }
    inflow2540.data.push({
      year: startYear,
      month: startMonth,
      value: inflow2540Master.data[startMonth - 1].value,
    });
    startMonth++;
  }
  return inflow2540;
}

export function GetInflowsLineData(
  selectedInflowsArr,
  actualWaterData,
  manualInflows,
  manualOutflows,
  selectedDam,
  startPredictionYear,
  startPredictionMonth,
  totalMonthLength,
  predictionPeriod,
  orginValue,
  Line,
  i
) {
  let inflowLine = { data: [] };
  for (let j = 0; j < selectedInflowsArr[i].length; j++) {
    if (manualInflows[i][j] === undefined) {
      manualInflows[i][j] = [];
    }
    const inflowName = selectedInflowsArr[i][j].split(" ")[0];
    const inflowPercentage = selectedInflowsArr[i][j].split(" ")[1] || "100%";
    let monthLength =
      parseInt(selectedInflowsArr[i][j].split(" ")[2]) ||
      manualInflows[i][j].length;
    let startSplitPredictionMonth =
      (startPredictionMonth + totalMonthLength) % 12;
    if (startSplitPredictionMonth === 0) {
      startSplitPredictionMonth = 12;
    }
    let startSplitPredictionYear =
      startPredictionYear +
      Math.floor((startPredictionMonth + totalMonthLength) / 13);
    if (totalMonthLength + monthLength > predictionPeriod) {
      monthLength = predictionPeriod - totalMonthLength;
    }
    let predictionInflowData = filteredInflowData(
      inflowName,
      actualWaterData,
      startSplitPredictionYear,
      startSplitPredictionMonth,
      manualInflows[i][j],
      monthLength
    );
    Line.label = predictionInflowData.label + " " + inflowPercentage;
    let percent = inflowPercentage.slice(0, -1) / 100;
    for (let k = 0; k < predictionInflowData.data.length; k++) {
      let outflow = manualOutflows[i][totalMonthLength + k] || 0;
      orginValue =
        orginValue + predictionInflowData.data[k].value * percent - outflow;
      orginValue =
        orginValue < 0
          ? 0
          : orginValue > GetDamCapacity(selectedDam)
          ? GetDamCapacity(selectedDam)
          : orginValue;
      Line.data.push({
        year: predictionInflowData.data[k].year,
        month: predictionInflowData.data[k].month,
        value: orginValue,
      });
      inflowLine.data.push({
        year: predictionInflowData.data[k].year,
        month: predictionInflowData.data[k].month,
        value: predictionInflowData.data[k].value * percent,
      });
    }
    totalMonthLength += parseInt(monthLength);
  }
  return inflowLine;
}

//
export function AddInflowToLine(
  Line,
  inflowLine,
  originValue,
  outflowLine,
  namPanFlows,
  selectedDam
) {
  for (let i = 0; i < inflowLine.inflows.data.length; i++) {
    let outflow = outflowLine.inflows.data[i]?.value || 0;
    let namPanFlow = parseInt(namPanFlows[i]) || 0;
    originValue =
      originValue + inflowLine.inflows.data[i].value - outflow + namPanFlow;
    let damMaxCapacity = GetDamCapacity(selectedDam);
    if (originValue > damMaxCapacity) {
      originValue = damMaxCapacity;
    } else if (originValue < 0) {
      originValue = 0;
    }
    Line.data.push({
      year: inflowLine.inflows.data[i].year,
      month: inflowLine.inflows.data[i].month,
      value: originValue,
    });
  }
  return;
}

function cleanManualInflow(manualInflow) {
  let cleanedManualInflow = [];
  for (let j = 0; j < manualInflow?.length; j++) {
    if (manualInflow[j] === "") {
      cleanedManualInflow.push(0);
    } else {
      cleanedManualInflow.push(manualInflow[j]);
    }
  }
  return cleanedManualInflow;
}

export async function FetchInflowData(
  selectedDam,
  selectedInflowsArr,
  manualInflow,
  startPredictionYear,
  startPredictionMonth,
  startActualYear,
  startActualMonth
) {
  let algoList = [];
  let startSplitPredictionMonth = startPredictionMonth;
  let startSplitPredictionYear = startPredictionYear;
  for (let i = 0; i < selectedInflowsArr.length; i++) {
    let inflow = selectedInflowsArr[i];
    let inflowName = inflow.split(" ")[0];
    let inflowPercentage = inflow.split(" ")[1] || "100%";
    let adjust = inflowPercentage.slice(0, -1) / 100;
    let monthLength = parseInt(inflow.split(" ")[2]) || manualInflow[i]?.length;
    if (startSplitPredictionMonth > 12) {
      startSplitPredictionMonth = startSplitPredictionMonth - 12;
      startSplitPredictionYear++;
    }
    let algo_type;
    let model_type = null;
    let algo_year;
    if (inflowName.search("2") !== -1) {
      algo_type = "Inflow";
      let idx = inflowName.search("2");
      algo_year = inflowName.slice(idx, idx + 4);
    } else if (inflowName.search("Average") !== -1) {
      algo_type = "AverageInflow";
    } else if (inflowName.search("Manual") !== -1) {
      algo_type = "ManualInflow";
    } else if (inflowName.search("model") !== -1) {
      algo_type = "MLInflow";
      const inflow_component = inflowName.split("-");
      model_type = inflow_component[inflow_component.length - 1];
    }
    algoList.push({
      algo_type: algo_type,
      predict_year: startSplitPredictionYear,
      predict_month: startSplitPredictionMonth,
      duration: monthLength,
      adjust: adjust,

      input_list: cleanManualInflow(manualInflow[i]),
      algo_year: algo_year,
      model_type: model_type,
    });
    startSplitPredictionMonth = startSplitPredictionMonth + monthLength;
  }
  const response = await fetchInflowPredictionData(selectedDam, algoList);
  // const historicalInflow = await fetchHistoricalInflow(
  //   selectedDam,
  //   startActualYear,
  //   startActualMonth
  // );
  return response;
}

export async function FetchOutflowData(
  selectedDam,
  selectedOutflowsArr,
  manualOutflow,
  startPredictionYear,
  startPredictionMonth,
  startActualYear,
  startActualMonth
) {
  let algoList = [];
  let startSplitPredictionMonth = startPredictionMonth;
  let startSplitPredictionYear = startPredictionYear;
  for (let i = 0; i < selectedOutflowsArr.length; i++) {
    let outflow = selectedOutflowsArr[i];
    let outflowName = outflow.split(" ")[0];
    let outflowPercentage = outflow.split(" ")[1] || "100%";
    let adjust = outflowPercentage.slice(0, -1) / 100;
    let monthLength =
      parseInt(outflow.split(" ")[2]) || manualOutflow[i]?.length;
    if (startSplitPredictionMonth > 12) {
      startSplitPredictionMonth = startSplitPredictionMonth - 12;
      startSplitPredictionYear++;
    }
    let algo_type;
    let model_type = null;
    let algo_year;
    // MinOutflow = 'OutflowWithMinInflow'
    // MaxOutflow = 'OutflowWithMaxInflow'
    // ManualOutflow = 'ManualOutflow'
    // AverageOutflow = 'AverageOutflow'
    if (outflowName.search("Min") !== -1) {
      algo_type = "OutflowWithMinInflow";
      let idx = outflowName.search("2");
      algo_year = outflowName.slice(idx, idx + 4);
    } else if (outflowName.search("Max") !== -1) {
      algo_type = "OutflowWithMaxInflow";
      let idx = outflowName.search("2");
      algo_year = outflowName.slice(idx, idx + 4);
    } else if (outflowName.search("Average") !== -1) {
      algo_type = "AverageOutflow";
    } else if (outflowName.search("Manual") !== -1) {
      algo_type = "ManualOutflow";
    }
    algoList.push({
      algo_type: algo_type,
      predict_year: startSplitPredictionYear,
      predict_month: startSplitPredictionMonth,
      duration: monthLength,
      adjust: adjust,

      input_list: cleanManualInflow(manualOutflow[i]),
      algo_year: algo_year,
      model_type: model_type,
    });
    startSplitPredictionMonth = startSplitPredictionMonth + monthLength;
  }
  const response = await fetchOutflowData(selectedDam, algoList);
  // const historicalInflow = await fetchHistoricalInflow(
  //   selectedDam,
  //   startActualYear,
  //   startActualMonth
  // );
  return response;
}
