export class TreeNodeElement {
  constructor(value) {
    this.value = value;
    // this.inflowOptions = localStorage.getItem("inflowOptions");
  }

  getParent() {
    const parent = (() => {
      if ("percentage" in this.value) {
        return null;
      } else if ("months" in this.value) {
        const inflowOptions = JSON.parse(localStorage.getItem("inflowOptions"));
        return (
          inflowOptions.find(({ percentage }) =>
            percentage.some(({ id }) => id === this.value.id)
          ) || null
        );
      }
      //   else {
      //     for (const { percentage } of sampleData) {
      //       const state = percentage.find(({ months }) =>
      //         months.some(({ id }) => id === this.value.id)
      //       );
      //       if (state) {
      //         return state;
      //       }
      //     }
      //     return null;
      //   }
    })();
    return parent ? new TreeNodeElement(parent) : parent;
  }

  getChildren() {
    if ("percentage" in this.value) {
      return this.value.percentage.map((state) => new TreeNodeElement(state));
    } else if ("months" in this.value) {
      return this.value.months.map((city) => new TreeNodeElement(city));
    } else {
      return null;
    }
  }

  isBranch() {
    return "percentage" in this.value || "months" in this.value;
  }

  isEqual(to) {
    return to.value.id === this.value.id;
  }

  toString() {
    return this.value.name;
  }
}

export const syncOrAsync = function (value, returnAsync) {
  if (returnAsync) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(value), Math.random() * 500)
    );
  }
  return value;
};

export function isTotalMonthExceed(selectedInflows, predictionPeriod) {
  let totalMonths = 0;
  if (selectedInflows === undefined) return false;
  for (let i = 0; i < selectedInflows.length; i++) {
    totalMonths += parseInt(selectedInflows[i].value.value.split(" ")[2]);
  }
  return totalMonths > predictionPeriod;
}
