export const line1DarkColor = "rgba(255,0,0)";
export const line1LightColor = "rgba(255,0,0,0.5)";
export const line2DarkColor = "rgba(0, 0, 255)";
export const line2LightColor = "rgba(0, 0, 255, 0.5)";
export const line3DarkColor = "rgba(79,173,96)";
export const line3LightColor = "rgba(79,173,96,0.5)";
// 228	196	92
// 206	141	97

export const warningDarkRed = "rgba(176,0,4)";
export const warningDarkRedLight = "rgba(176,0,4,0.5)";
// export const warningRed = "rgba(252,50,59)";
// export const warningRedLight = "rgba(252,50,59,0.3)";
export const warningYellow = "rgba(253,181,6)";
export const warningYellowLight = "rgba(253,181,6,0.3)";

// export const warningDarkRed = "rgba(204,51,0)";
// export const warningDarkRedLight = "rgba(204,51,0,0.5)";
export const warningRed = "rgba(237,125,49)";
export const warningRedLight = "rgba(237,125,49,0.4)";
// export const warningYellow = "rgba(255,192,0)";
// export const warningYellowLight = "rgba(255,192,0,0.5)";
