import React, { useEffect, useState, useRef } from "react";
import { GetOutflowOptions } from "../../db/water_data";
import TreeSelect, {
  DefaultOption,
  getDefaultOptionProps,
} from "mui-tree-select";
import { TextField } from "@mui/material";
import { getMonthleft } from "../util/month";
import { TreeNodeElement, syncOrAsync } from "./tree_node";

const OutflowSelector = ({
  value,
  setValue,
  selectedDam,
  predictionPeriod,
  manualOutflows,
}) => {
  const [outflowOptions, setOutflowOptions] = useState([]);
  const [runAsync, setRynAsync] = useState(false);
  const [branch, setBranch] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      console.log("selectedDam", selectedDam);
      let outflowOption = await GetOutflowOptions(selectedDam);
      console.log("outflowOption", outflowOption);
      setOutflowOptions(outflowOption);
      setBranch(null);
      // set data to local storage
      localStorage.setItem("outflowOptions", JSON.stringify(outflowOption));
    };
    fetchData();
  }, [selectedDam]);
  return (
    <div style={{ width: 400 }}>
      <TreeSelect
        sx={{ m: 1 }}
        branch={branch}
        onBranchChange={(_, branch) => {
          void setBranch(branch);
        }}
        // Allow adding months.
        freeSolo={
          (branch === null || branch === void 0 ? void 0 : branch.value) &&
          "months" in branch.value
        }
        // addFreeSoloText="Add month: "
        getChildren={(node) =>
          syncOrAsync(
            node
              ? node.getChildren()
              : outflowOptions.map((country) => new TreeNodeElement(country)),
            runAsync
          )
        }
        getParent={(node) => syncOrAsync(node.getParent(), runAsync)}
        getOptionDisabled={(option) => {
          var _a;
          return (
            option.isBranch() &&
            !((_a = option.getChildren()) === null || _a === void 0
              ? void 0
              : _a.length)
          );
        }}
        isBranch={(node) => syncOrAsync(node.isBranch(), runAsync)}
        // isOptionEqualToValue={(option, value) => {
        //   return option instanceof FreeSoloNode
        //     ? value instanceof FreeSoloNode &&
        //         value.toString() === option.toString() &&
        //         (option.parent === null || value.parent === null
        //           ? option.parent === value.parent
        //           : option.parent.isEqual(value.parent))
        //     : value instanceof FreeSoloNode
        //     ? false
        //     : option.isEqual(value);
        // }}
        multiple
        renderInput={(params) => (
          <TextField
            {...params}
            error={getMonthleft(predictionPeriod, value, manualOutflows) < 0}
            label="Outflows"
            helperText={
              getMonthleft(predictionPeriod, value, manualOutflows) < 0
                ? "กรุณากรอกค่าไม่เกินจำนวนเดือนที่เลือก."
                : getMonthleft(predictionPeriod, value, manualOutflows) === 0
                ? `ใส่ได้ ${predictionPeriod} เดือน, ครบแล้ว`
                : `ใส่ได้ ${predictionPeriod} เดือน, คงเหลือ ${getMonthleft(
                    predictionPeriod,
                    value,
                    manualOutflows
                  )} เดือน`
            }
          />
        )}
        renderOption={(...args) => (
          <DefaultOption
            {...((props, node) => {
              var _a, _b;
              return {
                ...props,
              };
            })(getDefaultOptionProps(...args), args[1])}
          />
        )}
        value={value || []}
        onChange={(_, value) => {
          setValue(value);
        }}
      />
    </div>
  );
};

export { OutflowSelector };
